@import 'design/template/mixin_templates';

/*-----------------------------
    GENERAL
-------------------------------*/

*, html {
  outline: 0;
  margin: 0;
  padding: 0;
}

body {
  list-style-position: inside;
  font-family: verdana, arial, sans-serif;
  font-size: 13px;
  line-height: 1.2;
}


@include titreBaseSize();


h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin: 0;
  padding: 0;
}

h1, .h1 {
  font-size: 1.3em;
  font-weight: 400;
}

h2, .h2 {
  width: 100%;
  font: 1.8em LeagueGothicRegular, arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: .05em;
  margin: 0 0 15px;
  padding: 0 0 15px;
}

h3, .h3 {
  text-transform: uppercase;
  font: bold 1.2em arial, sans-serif;
  padding-bottom: 5px;
}

h4, .h4 {
  margin-bottom: 10px;
  font-family: arial, serif;
  font-weight: 400;
  letter-spacing: .15em;
  text-transform: uppercase;
}

a img {
  border: none;
}

iframe {
  max-width: 100%;
}

/*-----------------------------
    HEADER
-------------------------------*/

#header {
  border: none;
  position: relative;
  margin: 0;
}

#headercontent {
  position: relative;
  height: 150px;
}

#headercontent a {
  text-decoration: none;
  font-family: arial, sans-serif;
  float: left;
  display: block;
  margin-bottom: 0;
}

#headercontent a img {
  border: none;
  max-height: 160px;
}

.slogan {
  width: 440px;
  text-align: right;
  position: absolute;
  top: 55px;
  right: 0;
  font: normal 1.4em PTSans, arial, serif;
  margin: 0;
}

#wrapper #header {
  height: 160px !important;
}

.slogan {
  min-height: 20px;
}

/*-----------------------------
    CONTENT
-------------------------------*/

#wrapper {
  width: 100%;
  max-width: 1080px;
  border-radius: 5px;
  margin: 20px auto;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
}

#content {
  font-family: PTSans, arial, sans-serif;
  font-size: 1.2em;
}

.content_p a, .contents-tabs button {
  text-decoration: none;
}

#contentpage ul {
  margin: 10px 10px 10px 30px;
  list-style-type: circle;
}

#contentfull, #contentpage {
  margin-top: 0;
  min-height: 500px;
}

/*-----------------------------
    MENU
-------------------------------*/

#menu-navigation {
  margin: 20px auto;
  text-align: center;
}

#nav-principal {
  width: auto;
  overflow: visible;
  text-align: center;
  margin: auto;
  display: inline-block;
}

#nav-principal li {
  display: inline-block;
  width: 150px;
  height: 150px;
  line-height: 150px;
  position: relative;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 0;
  border-radius: 50%;
  margin-right: 2px;
  transition: all .2s linear;

  &:hover {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

#nav-principal li a {
  font-family: LeagueGothicRegular, arial, sans-serif;
  font-size: 1.6em;
  letter-spacing: .05em;
  text-transform: uppercase;
  display: inline-block;
  width: 100%;
  text-align: center;
  height: auto;
  text-decoration: none;
  text-shadow: rgba(0,0,0,0.2) -1px -1px 0;
  border-radius: 50%;
  line-height: initial;
  vertical-align: middle;
  padding: 100px 10px;
  box-sizing: border-box;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-top: 50%;
}

#nav-principal #photos {
  //height:auto;
  //line-height:initial;
  //padding:49px 0;
}

#menu {
  margin-top: 50px;
  clear: both;
}

#menu ul {
  text-align: center;
  margin: auto;
}

#menu ul li {
  display: inline-block;
  margin: 0 5px;
}

#menu ul li a {
  text-decoration: none;
  font-size: 1.3em;
  padding-left: 20px;
  font-family: PTSans, arial, sans-serif;
}

#menu {
  margin-bottom: 20px;
}

#menu ul > li > ul {
  position: absolute;
}

#menu ul li ul {
  display: none;
}

#menu ul li ul li li {
  background: #FFF;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
}

#menu-navigation ul ul {
  display: none;
}

nav#menu > a {
  display: none;
}

#nav-principal #accueil,
#nav-principal #boutique,
#nav-principal #photos,
#nav-principal #livre,
#nav-principal #blog,
#nav-principal #contact {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

/*-----------------------------
    BOUTIQUE
-------------------------------*/

#boutiqueaccueil {
  width: 920px;
  margin: 20px auto;
  padding: 0 10px 10px;
}

.produits {
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
  width: 100%;
}

.produits .container-etiquette {
  position: relative;
  left: 0;
  width: 100%;
  padding: 0;
  box-sizing: border-box;

  .produit_etiquette {
    width: 100%;
    position: absolute;
    box-sizing: border-box;
  }
}

.produits img, .galerie img {
  margin: 10px 0;
  width: 100%;
}

.produits img {
  margin: 0;
  box-sizing: border-box;
}

.produits p, .galerie p {
  text-align: center;
  font-size: .85em;
}

.produits p {
  margin: 5px 0 !important;
}

.produits p.titreprod {
  height: 25px;
  font-family: PTSans, arial, sans-serif;
  font-size: .9em !important;
  line-height: 1em !important;
}

.produits p.attr {
  height: auto;
  font-weight: 700;
  font-size: .85em;
}

.produits b {
  font-size: 1em;
  font-family: verdana, arial, sans-serif;
}

.produits select {
  width: 90% !important;
}

.produit_etiquette {
  height: 26px;
  width: 100%;
}

.produit_etiquette:after {
  margin-left: -9px;
  border-width: 9px;
}

.produit_etiquette2 {
  bottom: 0;
}

.produit_etiquette h3, .produit_etiquette2 h3, .produit_etiquette3 h3,
.produit_etiquette .h3, .produit_etiquette2 .h3, .produit_etiquette3 .h3 {
  padding-top: 5px;
  border-bottom: none;
}

.produits-base {
  float: left;
  //height:262px;
  width: 18%;
  border-radius: 3px;
  margin: 10px 1%;
  box-sizing: border-box;
  padding: 7px;
  background-size: cover;
}

.produits-base:hover {
  background-size: 100% 100%;
}

.produits-base .produits a.addbasket, #contentpage .produits-base-bis .produits a.addbasket {
  cursor: pointer;
  display: block;
  margin-left: 8px;
  padding: 5px 5px 5px 20px;
}

.produits-base .produit_etiquette h3,
.produits-base .produit_etiquette .h3 {
  font-size: 12px;
  border-bottom: none;
}

.produits-base .produit_etiquette2 h3,
.produits-base .produit_etiquette2 .h3 {
  font-size: 12px;
  border-bottom: none;
}

.is_etiquette .remise {
  top: 128px !important;
}

#boutique .is_etiquette .remise {
  top: 170px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 20px;
}

.nomprod {
  font-weight: 700;
  font-size: .9em !important;
  line-height: 1em !important;
}

.prixprod {
  font-weight: 700;
  font-size: 1.1em !important;
}

.produits .remise {
  position: absolute;
  width: 56px;
  height: 56px;
  background: url(/images/vignette_promo.png) no-repeat top left;
  font-size: 10px;
  top: 130px;
  padding-top: 15px;
  color: white;
  right: -15px;
}

.oldprix {
  font-size: .9em;
  text-decoration: line-through;
  color: red;
}

.ajouter-panier .addbasket {
  cursor: pointer;
  display: block;
  background: url(../images/panier_add.png) no-repeat;
  margin-left: 8px;
  padding: 10px 10px 10px 20px;
  background-position: left 0 bottom 6px;
}

#paiementchoix .button, .button {
  padding: 10px;
}

#carousel .produits .produit_etiquette {
  position: absolute;
  z-index: 100;
  width: 100%;
}

.boutique_header > p {
  padding: 20px 0;
}

.content .produits {
  height: 303px;
}

/*-----------------------------
    GALERIE
-------------------------------*/

#div-slider {
  clear: both;
  margin: 20px 0 0 13px;
}

ul#slider {
  width: 100%;
  margin: 0;
}

ul#slider li {
  width: 255px;
  padding: 25px;
  list-style: none;
  display: inline-block;
}

ul#slider li img {
  padding-bottom: 5px;
}

ul#slider a {
  text-decoration: none;
  font-size: .9em;
}

#carousel {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin: auto;
}

#carousel div:not(.clearfix) {
  position: relative;
}

#carousel div.produits {
  //height:250px;
  float: left;
  list-style: none;
}

#simplePrevious, #simpleNext {
  cursor: pointer;
  float: left;
  text-indent: -9999px;
  width: 23px;
  height: 143px;
  position: relative;
  bottom: 270px;
}

#simpleNext {
  position: relative;
  left: 322px;
}

#simplePrevious {
  right: 22px;
}

#contentgalerie {
  width: 100%;
  margin: auto;
}

.galerie-base {
  float: left;
  border-radius: 3px;
  margin: 10px 15px;
  padding: 5px;
  background-size: 100% 100%;
  width: 200px;
}

.galerie-base:hover {
  //background:url(/images/imgd281series/1/galerie-bg-hover.png) no-repeat;
  background-size: 100% 100%;
}

.galerie {
  text-align: center;
  //height:174px;
  padding: 5px;
}

.galerie a {
  font-family: arial, sans-serif;
  text-decoration: none;
}

.diaporama {
  text-align: center;
  text-decoration: none;
  margin-top: 0;
  padding-top: 20px;
}

.diaporama a {
  text-decoration: none;
}

.diaporama a:hover {
  text-decoration: underline;
}

/*#contentfull > .bx-wrapper .bx-controls-direction{
    display:none;
}*/
.bx-wrapper {
  max-width: 100% !important;
  position: relative;
}

/*-----------------------------
    LIVRE D'OR
-------------------------------*/

.message_lo {
  font-family: arial, sans-serif;
  position: relative;
  text-align: left;
  margin: 15px 0;
  padding: 15px;
}

.message_lo p {
  text-align: justify;
  margin-bottom: 5px;
  font: normal 1em arial, sans-serif;
}

.message_lo .message {
  text-align: justify;
  margin-bottom: 5px;
  font: normal 1em arial, serif;
}

.message_lo p.note {
  position: absolute;
  right: 15px;
  top: 15px;
  padding-left: 35px;
  padding-top: 3px;
}

.message_lo p.web {
  text-align: justify;
  font-size: .8em;
  margin: 10px 0 5px;
}

/*a.addmsglo{
    font-size:1em;
    font-family:LeagueGothicRegular,arial,sans-serif;
    text-transform:uppercase;
    letter-spacing:.15em;
    display:block;
    width:105px;
    height:55px;
    text-decoration:none;
    text-align:center;
    margin:20px 0;
    padding:25px 0;
}*/
a.addmsglo {
  font-size: 1em;
  font-family: LeagueGothicRegular, arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: .15em;
  display: block;
  width: 155px;
  height: 155px;
  text-decoration: none;
  text-align: center;
  margin: 20px auto;
  padding: 32px 25px;
  border-radius: 50%;
  box-sizing: border-box;
}

/*-----------------------------
    CONTACT
-------------------------------*/

#addmsg input {
  margin-top: 8px;
  margin-bottom: 20px;
  padding: 5px;
}

#addmsg textarea {
  margin-bottom: 10px;
  height: 125px;
  width: 640px;
  overflow: auto;
  padding: 5px;
}

#addmsgleft {
  float: left;
  margin-right: 50px;
}

#addmsgright {
  float: right;
}

#addmsg input[type=submit] {
  font-family: arial, sans-serif;
  font-weight: 700;
  letter-spacing: .15em;
  clear: both;
  display: block;
  width: 230px;
  text-decoration: none;
  margin-left: 684px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 5px;
  text-shadow: rgba(0,0,0,0.2) -1px -1px 0;
  padding: 5px;
}

#addmsg a {
  text-decoration: none;
}

.texthautcontact {
  font: 1.4em PTSans, arial, sans-serif;
  text-align: center;
  clear: both;
  margin: 50px 0 20px;
}

#contacthaut {
  width: 940px;
  margin-bottom: 20px;
  overflow: hidden;
}

#contactright {
  float: right;
  width: 550px;
}

#contactleft {
  float: left;
  width: 340px;
  min-height: 150px;
  font-size: 1.3em;
}

#contactleft h2,
#contactleft .h2 {
  width: 350px;
}

#addmsg input, #addmsg textarea, #form1 input, #form1 textarea {
  width: 202px;
  font-family: arial, serif;
  margin: 3px;
  padding: 5px;
}

#addmsg p, #form1 p {
  font-family: arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: .15em;
  margin: .5em 0;
}

#addmsg label, #form1 label {
  display: block;
  font: 11px arial, sans-serif;
  padding-left: 15px;
  margin: 5px 3px 3px;
}

/*-----------------------------
    FORMULAIRE
-------------------------------*/

#form1 {
  position: relative;
  padding-top: 10px;
  width: 938px;
}

#form1 fieldset {
  border: none;
  float: left;
  display: inline;
  width: 220px;
  margin: 0 0 0 20px;
  padding: 0;
}

#form1 textarea {
  height: 125px;
  width: 640px;
  overflow: auto;
}

#form1 input.button {
  font-size: 15px;
  font-family: LeagueGothicRegular, arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: .15em;
  clear: both;
  display: block;
  width: 105px;
  height: 105px;
  border: none;
  text-decoration: none;
  margin-left: 800px;
  cursor: pointer;
  position: relative;
  bottom: 60px;
}

textarea#message {
  height: 216px;
}

#connexion-inscription h2,
#connexion-inscription .h2 {
  width: auto;
}

.contents-tabs a.button, .contents-tabs button {
  border: 1px solid;
}

.contents-tabs button {
  background-color: transparent;
}

.contents-tabs button:hover {
  text-decoration: underline;
}

/*-----------------------------
    FOOTER
-------------------------------*/

#wrapper #footer {
  display: block;
  background: none;
  margin: 20px 0;
  padding: 0;
}

#wrapper #footer a {
  text-decoration: none;
}

#wrapper #footer a:hover {
  text-decoration: none;
}

#footer {
  text-align: center;
  margin: 0;
  padding: 0 0 15px;
}

#pub {
  width: 728px;
  height: 90px;
  text-align: center;
  margin: 20px auto;
}

#pub_vide {
  text-align: center;
  margin: 20px auto;
}

#facebook {
  margin-top: 0;
}

#footer p, #footer a, #footer td {
  color: #000;
}

#footer * img {
  max-width: 80% !important;
  width: auto !important;
}

/*-----------------------------
    AUTRE
-------------------------------*/

.clearer {
  clear: both;
  margin: 0;
  padding: 0;
}

#border {
  height: 10px;
  width: 100%;
  margin: 10px 0;
}

#GoogleMapAffichage {
  color: #000 !important;
}

body #header, body #content_top, body #coin-slider, body #content_top #coin-slider, body #menu, body #wrapper #contentpage #menu, body #wrapper #contentpage #boutiqueaccueil, body #wrapper #contentpage_left #boutiqueaccueil, body #wrapper #contentpage_center #boutiqueaccueil, body #wrapper #contentpage_right #boutiqueaccueil, #form1 legend {
  display: none;
}

#wrapper #header, body #wrapper #coin-slider, body #wrapper #contentfull #menu, body #wrapper #boutiqueaccueil, body #footer {
  display: block;
}

ul#slider a:hover, .content_p a:hover, #addmsg a:hover {
  text-decoration: underline;
}

#simplePrevious:hover, #simpleNext:hover, a.addmsglo:hover, #form1 input.button:hover {
  background-position: bottom;
}

#fiche-produit {
  .wrap-description {
    h1, .h1 {
      font-size: 24px;
    }

    .brand {
      background: transparent !important;
    }
  }
}


#fiche-produit .top_fiche .reservation .reservation__addbasket .block-addbasket {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  > .button {
    flex: 0 1 auto;
    margin: 15px 0;
    display: inline-block;

    .fa {
      margin-right: 10px;
    }
  }

  .addbasket .fa {
    display: none;
  }

  > .button + .button {
    margin-left: 15px;
  }

}

.modal {
  z-index: 2041;
}

.modal-backdrop {
  z-index: 2040;
}


/********************* A GARDER POUR LES MODELS ********************/

/*Gerer les images dans les tableaux*/
.links_p {
  position: relative;
}

#content img {
  max-width: 100% !important;
  height: auto !important;
  display: inline-block;
}

#content .zoomPupImgWrapper > img, #content .zoomWrapperImage > img {
  max-width: none !important;
}

table {
  position: relative;
  table-layout: fixed;
}

td {
  max-width: 50%;

  img {
    max-width: 100% !important;
  }
}

#content td img {
  max-width: 100% !important;
}

#footer.footer_p .links_p td img, #footer.footer_p .links_p img {
  width: auto !important;
  max-width: 100% !important;
}

.links_p table {
  max-width: 100% !important;
}

/*Titre des commentaires du livre d'or ne prend pas toute la largeur*/
.message_lo h4,
.message_lo .h4 {
  width: 80%;
}

.container-comment, .content-cmonsite {
  overflow: hidden;
}

.content-cmonsite {
  overflow: visible;
}

/*Slider*/
#sliderContent, .sliderImage span, .sliderImage li {
  width: 100% !important;
}

.sliderImage {
  text-align: center !important;
}

.sliderImage img {
  text-align: center;
  width: auto;
  max-width: 100% !important;
}

.produits-base, .galerie-base {
  position: relative;
  box-sizing: border-box;
}

.preview {
  max-width: 95%;
}


/*-----------------------------
    FLUX PANIER
-------------------------------*/

#flux-panier.template_flux_panier.template_flux_panier_1 {

  ul li {
    list-style: none;
  }
}

/* Commandes */

#order .commandes td {
  padding: 10px 0;
  border-top: 1px solid rgba(128, 128, 128, .1);
}

#order .commandes-details {
  > td > div {
    border-bottom: none;
  }

  .infosclient.s6 {
    &, &:first-child {
      width: 100%;
      margin: 10px 0 0;
    }
  }
}

/******************************************\
|************* MEDIA QUERIES **************|
\******************************************/

@media screen and (max-width: 1030px) {
  #wrapper {
    width: 90% !important;
    box-sizing: border-box;
  }
  #contentpage_center, #boutiqueaccueil {
    width: 100%;
    float: none;
  }
  #boutiqueaccueil .bx-window, #boutiqueaccueil .bx-wrapper {
    width: 100% !important;
  }
  .slidershadow {
    width: 100%;
  }
  /*.slogan, .slogan_p{
      max-width: 80% !important;
      left: initial;
      right: 2%;
      top: 15;
      font-size: 18px;
      text-align: right;
  }*/
  #footer img {
    max-width: 100%;
    height: auto;
  }
  .produits-base, .galerie-base {
    width: 23%;
    margin: 0 1% 10px;
  }
  .produit_etiquette {
    width: 100%;
    max-width: 100% !important;
    left: 0;
  }
  .search-bar {
    width: 95%;
  }
}

.logo img {
  max-height: 110px !important;
  max-width: 100%;
}

@media screen and (max-width: 768px) {

  #contentpage_center {
  }
  /*Suppression des blocs en dehors du contenu*/
  #block-flottant-search, #minisite-sidebar {
    display: none;
  }
  .bx-wrapper {
    height: auto;
    width: 80%;
    margin: 10px auto;
  }
  .bx-wrapper a.one_image {
    height: 150px;
  }

  .categorie, .div-content {
    float: none;
    display: block;
    margin: 3%;
    width: 94%;
    box-sizing: border-box;
    padding: 1%;
  }
  .produits-base, .galerie-base {
    width: 31%;
    margin: 0 1% 10px;
  }

}

@media screen and (max-width: 680px) {
  #wrapper #headercontent, #wrapper #header {
    height: auto !important;
  }
  .slogan {
    left: 0 !important;
    top: 0 !important;
    right: auto !important;
    bottom: auto !important;
    text-align: center !important;
    font-size: 18px !important;
    width: 100% !important;
    position: relative !important;
    margin-bottom: 15px !important;
    float: none !important;
    height: auto !important;
  }
  .logo {
    padding-bottom: 15px;
    position: static !important;
    display: block !important;
    margin: 0 auto !important;
    padding-top: 15px;
    height: auto !important;
    text-align: center;
    width: 100% !important;
    float: none !important;

    img {
      max-height: 70px !important;
      width: auto !important;
      max-width: 100% !important;
      height: auto !important;
      position: static !important;

    }
  }
  .search-bar {
    width: 90%;
  }
  /*Fiche produit*/
  #fiche-produit #formulaire .form-group {
    float: none !important;
  }
  #fiche-produit .input-form-petit {
    width: auto !important;
  }
  #fiche-produit .input-form-moyen {
    width: 100% !important;
  }
  #fiche-produit .wrap-images, #fiche-produit .wrap-description {
    width: 100% !important;
    float: none !important;
  }
  #wrapper table {
    width: 100% !important;
  }
  /*Desactivation du hover sur images produits*/
  .zoomWindow, .zoomPup {
    display: none !important;
  }
  .zoomPup, .zoomPad {
    cursor: initial !important;
  }
  .zoomPad {
    display: inline-block;
    width: 80% !important;
    margin: 0 10% !important;
    text-align: center;
  }
  .zoomPad img {
    width: 100% !important;
    max-height: 100% !important;
    opacity: 1 !important;
  }
  /*Fin desactivation*/
  .sliderImage span {
    display: none !important;
  }
}

@media screen and (max-width: 600px) {
  form .row .col.s3 {
    width: 25%;
  }
  .form-group textarea {
    width: 100% !important;
  }
  .produits-base, .galerie-base {
    width: 46%;
    margin: 0 2% 10px;
  }
  .thumbnail {
    float: left;
    width: 42%;
    margin: 2.5%;
  }
  td {
    max-width: 100%;
  }
  /*Ajuste tous les tableaux des templates*/
  .template table, .template td, .template tbody, .template tr {
    width: 100%;
    display: inline-block;
    margin: 5px auto;
  }
  @import 'design/helper/responsive/mobile/mobile';
  .ui-datepicker {
    width: 98% !important;
    font-size: .75em;
    left: 0 !important;
  }
  #nav-principal li {
    width: 50%;
  }

}

@media screen and (max-width: 500px) {
  .message_lo {
    margin: 15px 5px;
  }
  .search-bar {
    width: 85%;
  }
  #panier {
    right: 0 !important;
    width: 100% !important;
    box-sizing: border-box;
  }
  .ui-datepicker {
    left: 0 !important;
  }
  #nav-principal li {
    width: 100%;
  }
  a.addmsglo {
    width: 100%;
    box-sizing: border-box;
  }
  .row.two-column-blog .blog-sidebar .blog-sidebar-bloc {
    width: 100% !important;
  }
}

@media screen and (max-width: 400px) {
  #wrapper {
    width: 95% !important;
    box-sizing: border-box;
  }
  .produits-base, .galerie-base {
    width: 75% !important;
    margin: 0 auto 12px !important;
    padding: 15px;
    display: block;
    margin-left: 12.5% !important;
  }
  .thumbnail {
    float: none;
    width: 75%;
    margin: 0 auto 12px;
    padding: 15px;
  }
  .content-payment-global {
    font-size: 15px !important;
  }
  a.addbasket.button {
    display: inline-block !important;
    padding-left: 15px;
    width: auto !important;
  }
}

@media screen and (max-width: 360px) {
  #wrapper .in {
    padding: 15px 8px;
  }
  ul.sous_navigation {
    margin: 15px 0 15px 10px;
  }
  .message_lo {
    margin: 15px 0;
  }
  #content form {
    overflow: hidden;
  }
  form .row .col {
    padding: 0 2px;
  }
  form .row .col.s3 {
    width: 50%;
  }
}

@media screen and (max-width: 320px) {

}

@media screen and (min-width: 680px) {
  nav.menu li:hover > ul {
    display: block !important;
  }
  .menu_burger {
    display: none;
  }


  #nav-static-principal > ul {
    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }
  #nav-static-principal > ul > li {
    > a {
      display: none;
    }

    > ul {
      position: relative;
      display: block;
      float: left;
    }

    li:hover ul {
      display: block;
      z-index: 999;
    }
  }
}

@media screen and (max-width: 680px) {
  #nav-static-principal {
    display: none;
  }
  a.addmsglo {
    background: #BBBBBB;
    border: 2px solid #414141;
    height: auto;
    padding: 10px;
  }
  li.contact {
    margin-bottom: 20px !important;
  }
  #menu-navigation nav {
    right: auto;
  }
  nav {
    width: 100%;
    text-align: center;
    z-index: 9999;
  }
  #menu ul li ul {
    display: block !important;
    position: static !important;
    opacity: 14 !important;

    li {
      padding: 0;
      background: transparent;
      border: none;
    }
  }
  #nav-principal li {
    line-height: normal;
    height: auto;
    margin-top: 0;
    border-radius: 0;

    &:hover {
      margin-top: 0;
    }
  }
  /* Nav mobile */
  nav ul {
    margin: 0;
  }
  nav.nav_ouverte ul li {
    float: left;
    z-index: auto !important;
    position: relative;
    width: 100% !important;
    padding: 0;
    text-align: center;
  }
  nav.nav_fermee > ul {
    display: none !important;
  }
  #nav-principal > ul {
    display: none;
  }
  nav.nav_ouverte > ul {
    display: block !important;
  }
  .hasDropdown {
    display: block !important;
    opacity: 1 !important;
  }
  nav ul > li {
    border: none;
    width: 100%;
    display: block;
    margin: 0;
    position: relative;
    box-sizing: border-box;
  }
  .menu_burger {
    display: block;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 5px;
    width: 100%;
    position: relative;
    z-index: 9999;
    box-sizing: border-box;
  }
  .menu_burger::before {
    font-family: FontAwesome, sans-serif;
    content: "\f0c9";
    margin-right: 5px;
  }
  nav.nav_ouverte > ul {
    display: block;
    width: 100%;
    z-index: 9999;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #fff;
  }
  #nav-principal li {
    display: block;
    position: relative;
    overflow: visible;
    text-align: center;
    height: auto;
    margin: 0 !important;
    top: 0;
    box-sizing: border-box;

    &.back {
      display: none !important;
    }

    &:hover {
      margin: 0 !important;
    }
  }
  #nav-principal li a {
    padding: 8px 8px !important;
    height: auto;
    position: static !important;
    border-radius: 0 !important;
    line-height: 15px !important;
    float: none !important;
    margin: 0 auto !important;
    top: 0 !important;
    width: 100% !important;
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
    transform: translateY(0);
  }
  nav ul li a span {
    font-size: 17px;
  }
  .hasDropdown ul + span::after {
    font-family: FontAwesome, sans-serif;
    content: "\f0d7";
  }
  .dropDown {
    position: absolute;
    right: 2%;
    top: 0;
    font-weight: bold;
    display: block;
    height: 25px;
    width: 8%;
    cursor: pointer;
    text-align: center;
    font-size: 1.2rem;
    padding-top: 5px;
    background-color: transparent !important;
  }
  nav > ul > li ul {
    display: block;
    position: relative;
    text-align: left;
    border: none;
    opacity: 1;
    height: auto !important;
    width: 100% !important;
    visibility: visible !important;
  }
  nav li ul li a {
    padding: 5px;
  }
  nav li ul li {
    text-align: center;
    border-bottom: none;
  }
  nav > ul > li > a.current, #nav-principal li a:hover {
    background: transparent;
  }
  .sousmenu_ouvert {
    z-index: 99999 !important;
  }
  nav ul li.sousmenu_ouvert > ul {
    display: block !important;
    position: relative;
    z-index: 999;
    left: 0;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, .7);
    box-sizing: border-box;
  }
  nav ul li.sousmenu_ouvert > ul li a {
    margin: 5px;
    color: #000 !important;
    background: #fff;
  }
  nav ul li.sousmenu_ouvert > ul li a:hover {
    text-decoration: none;
    color: #fff;
    background: #fff !important;
  }
  #menu ul li {
    width: auto !important;
    display: inline;
  }
  #menu ul li ul {
    display: inline !important;
  }
  /* FIN Nav mobile */
}

.nav-static-breadcrumb {
  margin-top: 10px;
  margin-bottom: 5px;

  li {
    list-style-type: none !important;
  }
}

.nav-static-breadcrumb li:not(:last-child)::after {
  content: ' > ';
}

#fiche-produit .wrap-description .ajouter-panier .quantite {
  margin-right: 0;
}

.wrap-description .bloc-quantite .bloc-bt-quantity .bt-quantity {
  height: auto;
}